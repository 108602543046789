<template>
  <validation-observer ref="simpleRules">
    <item-form />
    <save-button
      :save-action="submitForm"
      :is-disabled="submitStatus"
    />
  </validation-observer>
</template>
<script>
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import SaveButton from '@/layouts/components/common/SaveButton.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ItemForm from '@/views/Admin/Defines/Delivery_methods/ItemForm.vue'

export default {
  name: 'Edit',
  components: {
    ItemForm,
    SaveButton,
    ValidationObserver,
  },
  data() {
    return {
      required,
      submitStatus: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['deliveryMethods/dataItem']
    },
    saveData() {
      return this.$store.getters['deliveryMethods/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.$router.push('/defines/delivery-methods')
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
        this.submitStatus = false
      }
    },
  },
  created() {
    this.getData()
    localize('tr')
  },
  methods: {
    getData() {
      this.$store.dispatch('deliveryMethods/getDataItem', this.$route.params.id)
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submitStatus = true
          this.$store.dispatch('deliveryMethods/saveData', this.dataItem)
        } else {
          this.showToast({
            title: 'Uyarı!',
            icon: 'XIcon',
            text: 'Lütfen tüm alanları doldurun.',
            variant: 'danger',
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
